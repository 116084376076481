import { trigger, transition, useAnimation } from '@angular/animations';
import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';

import { moveFadeOut, moveFadeIn, fadeIn, fadeOut } from '@core/animations';

@Component({
  selector: 'app-auto-save',
  templateUrl: './auto-save.component.html',
  styleUrls: ['./auto-save.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('move', [
      transition(':enter', useAnimation(moveFadeIn, { params: { time: '200ms', slide: '5px' } })),
      transition(':leave', useAnimation(moveFadeOut, { params: { time: '200ms', slide: '5px' } })),
    ]),
    trigger('fade', [
      transition(':enter', useAnimation(fadeIn, { params: { time: '200ms', slide: '5px' } })),
      transition(':leave', useAnimation(fadeOut, { params: { time: '200ms', slide: '5px' } })),
    ]),
  ],
})
export class AutoSaveComponent implements OnInit {
  @Input() saving: boolean;
  @Input() saved: boolean;
  @Input() error: string;

  constructor() {}

  ngOnInit() {}

  get isCompleted(): boolean {
    return !this.saving && this.saved !== null;
  }
}
