<div class="stepper">
  <div class="stepper__steps">
    <ng-container *ngFor="let step of steps; let idx = index; trackBy: trackByFn">
      <a class="step"
         [attr.data-test]="step.id"
         [class.disabled]="step.disabled"
         [class.invalid]="step.invalid"
         [class.touched]="step.touched"
         [class.passed]="!step.invalid && step.touched"
         [class.active-step]="step.id === activeIndex"
         [class.not-clicked]="step.route === null && !withClick"
         routerLinkActive="active">
        <label (click)="goToStep($event, step)">
          <span class="index">{{ idx + 1}}</span>
          <span class="title">{{ step.label | translate }}</span>
        </label>
      </a>
    </ng-container>
  </div>
</div>
