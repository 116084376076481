import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-invalid-alert',
  templateUrl: './invalid-alert.component.html',
  styleUrls: ['./invalid-alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvalidAlertComponent implements OnInit {
  @Input() warnings: string[];
  @Input() hasCancel = true;

  @Output() submitted = new EventEmitter<void>();
  @Output() canceled = new EventEmitter<void>();

  public isModalOpened = false;

  constructor() {}

  ngOnInit() {}

  public open(): void {
    this.isModalOpened = true;
  }

  public close(): void {
    this.isModalOpened = false;
  }

  public submit(): void {
    this.submitted.emit();
    this.close();
  }

  public cancel(): void {
    this.canceled.emit();
    this.close();
  }

  public trackByFn(index) {
    return index;
  }
}
