import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DestroyableComponent } from '@models/destroyable.component';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import { takeUntil, filter } from 'rxjs/operators';
import { IStep } from './stepper.models';
import { StepperService } from './stepper.service';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepperComponent extends DestroyableComponent implements OnInit {
  @Input() public steps: IStep[];
  @Input() public activeIndex: number;
  @Input() public withClick = false;
  @Output() public touched = new EventEmitter<number>();
  @Output() public highlightErrors = new EventEmitter<boolean>();
  @Output() public stepChanged = new EventEmitter<IStep>();

  private nextStepIndex: number;

  constructor(
    public errorNotificationService: ErrorNotificationService,
    private router: Router,
    private route: ActivatedRoute,
    private stepperService: StepperService,
  ) {
    super();
  }

  ngOnInit() {
    this.stepperService.isOk$
      .pipe(filter((isOk) => isOk))
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: () => {
          if (this.nextStep.touched) {
            this.highlightErrors.emit(!!this.nextStep.invalid);
          }

          this.router.navigate([`./${this.nextStep.route}`], { relativeTo: this.route }).then(() => true);
          this.touched.emit(this.nextStep.id);
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'OTHER-PROBLEM');
        },
      });
  }

  public get activeStep(): IStep {
    return this.steps[this.activeIndex];
  }

  public get nextStep(): IStep {
    return this.steps[this.nextStepIndex];
  }

  public goToStep(event: Event, step: IStep): void {
    event.preventDefault();
    event.stopPropagation();
    if (step.disabled || step.id === this.activeIndex) {
      return;
    }
    this.nextStepIndex = step.id;

    if (step.route === null && this.withClick) {
      this.stepChanged.emit(step);
      return;
    }

    this.stepperService.goNextIsOk(true);
    if (step.route && !this.withClick) {
      this.router.navigate([step.route], { relativeTo: this.route }).then();
    }
  }

  public trackByFn(index) {
    return index;
  }
}
